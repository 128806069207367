import * as THREE from 'three';
/* eslint-disable import/extensions, import/no-unresolved */
// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
/* eslint-enable import/extensions, import/no-unresolved */

const AR = 4 / 3;

const getDimensions = el => {
    const { offsetWidth, offsetHeight } = el;

    return offsetWidth / offsetHeight > AR
        ? [offsetWidth, offsetWidth / AR]
        : [offsetHeight * AR, offsetHeight];
};

export default function Waves(el, {
    background = 'dark',
}) {
    if (navigator.webdriver) return;

    let [w, h] = getDimensions(el);

    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(w, h);
    el.appendChild(renderer.domElement);

    const camera = new THREE.PerspectiveCamera(75, AR, 0.1, 1000);
    camera.position.setZ(5);

    const scene = new THREE.Scene();
    scene.fog = new THREE.FogExp2(background === 'dark' ? 0x0B3C67 : 0xFBFBFB, 0.25);

    const light = new THREE.DirectionalLight('white', 1.5);
    light.position.set(0, 1, 5);
    scene.add(light);

    const geometry = new THREE.PlaneGeometry(20, 15, 60, 45);
    const material = new THREE.MeshPhongMaterial({
        color: 0x0B3C67,
        onBeforeCompile: shader => {
            const output = background === 'dark'
                ? '1.0 - min(line, 1.0)'
                : 'min(line, 1.0)';

            shader.vertexShader = `
                varying vec3 vUv;
                ${shader.vertexShader}
                `.replace('#include <begin_vertex>', `
                    vUv = position;
                    vec3 transformed = vec3( position );
                `);
            shader.fragmentShader = `
                varying vec3 vUv;
                varying float fractMultiplier;
                ${shader.fragmentShader}
                `.replace('#include <map_fragment>', `
                    float coord = vUv.y + (vUv.x * 0.75);
                    float line = fract(coord * 6. * ${h / w}) / (fwidth(coord * 6.));
                    float color = ${output}; // Line
                    color = pow(color, 1.0 / 2.2); // Gamma correction
                    diffuseColor = vec4(vec3(color) + vec3(
                        color + 0.0431,
                        color + 0.2352,
                        color + 0.4039
                    ), 1.0);
                `);
        },
    });
    const plane = new THREE.Mesh(geometry, material);
    scene.add(plane);

    const { position } = geometry.attributes;
    const clock = new THREE.Clock();

    function handleResize() {
        [w, h] = getDimensions(el);

        material.needsUpdate = true;
        camera.updateProjectionMatrix();
        renderer.setSize(w, h);
    }
    function animate() {
        const time = clock.getElapsedTime();

        for (let i = 0; i < position.count; i++) {
            const x = position.getX(i);
            const y = position.getY(i);
            const z = 0.5 * Math.sin(0.5 * x + time * 1.5)
                    + 0.45 * Math.sin(0.5 * x + time * 1.25) ** 2
                    + 0.55 * Math.sin(0.5 * y + time * 0.75) ** 2
                    + 0.25 * Math.sin(0.5 * y + time * 1.35)
                    + 0.35 * Math.sin(0.5 * (x + y) + time * 0.45) ** 2;

            plane.rotation.x = 0.5 * Math.sin(time * 0.5);
            plane.rotation.z = 0.25 * Math.sin(time * 0.15);
            position.setZ(i, z);
            position.needsUpdate = true;
        }

        renderer.render(scene, camera);
        requestAnimationFrame(animate);
    }

    window.addEventListener('resize', handleResize);

    animate();
}
