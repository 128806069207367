import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const getSlideOffset = swiper => swiper.slides[0].offsetWidth * -0.5;

export default function ImageCarousel(el) {
    const carousels = el.querySelectorAll('.swiper');

    Array.from(carousels).map(c => {
        const [prev, next] = c.querySelectorAll('nav > button');

        return new Swiper(c, { // eslint-disable-line no-new
            modules: [Navigation],
            navigation: {
                prevEl: prev,
                nextEl: next,
            },
            loop: true,
            slidesPerView: 'auto',
        });
    })
        .filter((_, i) => i % 2 === 0)
        .forEach(s => {
            const handleOffset = ss => {
                const offset = window.innerWidth < 768 ? 0 : getSlideOffset(ss);

                ss.params.slidesOffsetBefore = offset;
                ss.updateSlides();
            };

            s.on('init', handleOffset);
            s.on('resize', handleOffset);
        });
}
